
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import ActionButton from '@/components/Button/Button.vue';
import { ColorPickerColor, Property, Value } from '@/types/vjsf';

@Component({
  name: 'ColorPickerField',
  components: {
    ActionButton,
  },
})
export default class ColorPickerField extends Vue {
  @PropSync('value', { required: true })
  private color: Value|undefined;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly serverLocale!: string;

  private isColorPickerVisible = false;

  private colorPickerColor: ColorPickerColor = { hex: '#FFFFFF' };

  private setColor(color: ColorPickerColor): void {
    this.color = color.hex;
    this.colorPickerColor = color;
  }

  private closeColorPicker(): void {
    this.isColorPickerVisible = false;
  }

  protected resetColor(): void {
    this.color = undefined;
    this.colorPickerColor = { hex: '#FFFFFF' };
  }
}
